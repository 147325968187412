
$('.main').not('#main1').hide();

$('.show').click(function () {
    $('.show-active').removeClass('show-active');
    $(this).addClass('show-active');
    $('#main' + $(this).attr('target')).fadeIn(1500).siblings('.main').fadeOut(0);
    $(window).scrollTop(0); //사이드 메뉴를 클릭했을 때 페이지를 맨위로 올려줌
});


// Next Technology버튼을 클릭했을 때 사이드바 show-active와 연결
$('.show1').click(function () {
    $('.show-active1').addClass('show-active');
});
$('.show2').click(function () {
    $('.show-active2').addClass('show-active');
});
$('.show3').click(function () {
    $('.show-active3').addClass('show-active');
});


// animation 효과 TEST
// $(window).scroll(function () {
//     $(".animation .anm_mod").each(function () {
//         const position = $(this).offset().top;
//         const scroll = $(window).scrollTop();
//         const windowHeight = $(window).height();
//         if (scroll > position - windowHeight) {
//             $(this).addClass("active");
//         }
//         if (scroll < 100) {
//             $(this).removeClass("active");
//         }
//     });
// });

$(function () {
    $('a[href^="#"]').click(function () {
        const speed = 800;
        const href = $(this).attr("href");
        const target = $(href == "#" || href == "" ? "html" : href);
        const position = target.offset().top;
        $("html, body").animate({
            scrollTop: position
        }, speed, "swing");
        return false;
    });
});


// 모바일일때, 햄버거버튼 눌렀을때 gnb나오게
var menu = document.getElementById("menu");
document.getElementById("menu-btn").addEventListener("click", function () {
    menu.style.left = "0";
});

document.getElementsByTagName("p")[0].addEventListener("click", function () {
    menu.style.left = "100%";
});